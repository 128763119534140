import React from 'react';
import headshot from '../../assets/ml-site/xsm-matt-lemche-headshot-casual.jpeg';
import {ReactComponent as Instagram} from '../../assets/ml-site/icon-instagram.svg';
import twitterIcon from '../../assets/ml-site/twitter-dark-gray.png';
import instagramIcon from '../../assets/ml-site/instagram-dark-gray.png';

const Hidden = () => {
    return (
        <article className="article">
            <div className="banner">
                <img src={headshot} className="banner__image image" alt="headshot of performer Matt Lemche" />
            </div>
            <div className="article__copy">
                <h3 className="heading1">
                    Hidden Matt Lemche
                </h3>
                <p className="paragraph">
                Matt Lemche is a Toronto-based actor, filmmaker, and designer. He is Creative Co-Director of <a href="https://decadesproject.com/" className="link bold ital" target="_blank" rel="noreferrer">The Decades Project</a> along with <a href="https://www.christopherredman.com/" className="link" target="_blank" rel="noreferrer">Christopher Redman</a>. The Decades Project is a collaborative documentary series that explores long-lasting friendship and the creation of personal milestones, with episodes recurring every ten years. Matt Lemche has appeared in numerous film and TV productions, including <span className="ital bold">Moby Dick</span> (Gate Filmproduktion/TMG) alongside William Hurt and Ethan Hawke, as well as smaller-scale productions like <a href="https://vimeo.com/342367726" className="link ital bold"  target="_blank" rel="noreferrer">The Mortal Decree</a> (Dir. Henri Fabergé) alongside Kayla Lorette, Mark Little, and Alex Tindal. Matt Lemche has worked as a script coach on such productions as <span className="ital bold">Flashpoint</span> (CBS/CTV), <span className="ital bold">The Killing</span> (AMC), and <span className="ital bold">Yellowstone</span> (Paramount Network). Lemche’s short films have screened at the Atlanta Film Festival and Brooklyn Rooftops Film Festival (<span className="bold ital">The Haunting of Matt Lemche</span> with Peter Stevens), as well as at CineVegas (<a href="https://vimeo.com/3535518" className="link bold ital" target="_blank" rel="noreferrer">What the Captive Ate</a>). Since 2007, Lemche has been an active member of the comedy collective <a href="https://twitter.com/elephantempire_" className="link bold ital" target="_blank" rel="noreferrer">Elephant Empire</a>.
                </p>
                <p className="paragraph">Matt Lemche is currently co-chair of ACTRA Toronto Committee, outACTRAto, alongside Thalia Gonzalez Kane. <a href="https://www.actratoronto.com/committees/outactrato/" className="link bold ital" target="_blank" rel="noreferrer">The outACTRAto Committee</a> is dedicated to raising awareness Hidden queer performers, queer stories, and the place of 2SLGBTQIA+ people in the industry and society. </p>
                <Instagram/>
                <img src={twitterIcon} alt="" /><img src={instagramIcon} alt="" />
            </div>
           
            
        </article>
    );
};

export default Hidden;