import React from 'react';
import headshot from '../../assets/ml-site/sm-matt-lemche-headshot-casual.jpg';

const About = () => {
    return (
        <article className="article">
            <div className="banner">
                <img src={headshot} className="banner__image image" alt="headshot of performer Matt Lemche" />
            </div>
            <div className="article__copy">
                <h3 className="heading1">
                    About Matt Lemche (he/him)
                </h3>
                <p className="paragraph">Matt Lemche is a queer, Toronto-based actor, filmmaker, and script coach. He has been co-chair of ACTRA Toronto Committee, outACTRAto, since January of 2022. <a href="https://www.actratoronto.com/committees/outactrato/" className="link bold ital" target="_blank" rel="noreferrer">The outACTRAto Committee</a> is dedicated to raising awareness about 2SLGBTQIA+ performers, their stories, and their place in the industry and society. Lemche also sits on <a href="https://www.actra.ca/2slgbtqia-committee/" className="link bold ital" target="_blank" rel="noreferrer">ACTRA National's 2SLGBTQIA+ Committee</a>.
                </p>
                <p className="paragraph">
                Matt Lemche is Creative Co-Director of <a href="https://decadesproject.com/" className="link bold ital" target="_blank" rel="noreferrer">The Decades Project</a> alongside Christopher Redman. The Decades Project is a collaborative documentary series that explores long-lasting friendship and the creation of personal milestones, with episodes recurring every ten years. 
                </p>

                <p className="paragraph">
                Since 1996, Lemche has appeared in numerous film and television productions, including the upcoming <span className="ital">Washington Black</span> (20th Century Fox Television) alongside Sterling K. Brown, Ernest Kingsley Junior, and Tom Ellis, and, most recently, <span className="ital">Fellow Travelers</span> (Showtime) with Matt Bomer and Jonathan Bailey. Past credits include <span className="ital">Moby Dick</span> (Gate Filmproduktion/TMG) with William Hurt and Ethan Hawke, and the web series <span className="ital">The Mortal Decree</span> (Dir. Henri Fabergé) alongside Kayla Lorette, Mark Little, and Alex Tindal. Since 2007, Lemche has been an active member of the comedy collective Elephant Empire.
                </p>
                <p className="paragraph">
                Two of Lemche's self-produced short films have screened internationally: at the Atlanta Film Festival and Brooklyn Rooftops Film Festival (<span className="ital">The Haunting of Matt Lemche</span> with Peter Stevens), and at CineVegas (<span className="ital">What the Captive Ate</span>). 
                </p>
                <p className="paragraph">
                Matt Lemche has worked as a script coach since 2007 on productions such as <span className="ital">Mayor of Kingstown</span> (Paramount Network), <span className="ital">The Killing</span> (AMC), and <span className="ital">Yellowstone</span> (Paramount Network).
                </p>
                
            </div>
           
            
        </article>
    );
};

export default About;