import React from 'react';
import './TabNav.scss';

const TabNav = (props) => {
    return (
        <nav className="tab-nav">
          {props.children}
        </nav>
    );
};

export default TabNav;