import React  from 'react';
import './App.scss';
import {BrowserRouter as Router, Switch, Redirect,  NavLink, Route} from 'react-router-dom';
import Header from './components/Header/Header';
import TabNav from './components/TabNav/TabNav';
import About from './components/About/About';
import DemoReel from './components/DemoReel/DemoReel';
import CreditList from './components/CreditList/CreditList';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
// import Campaign from './components/Campaign/Campaign';
import Hidden from './components/Hidden/Hidden';


function App() {
    

  return (
    <>
      <Header />
      <main className="main">
        <section className="work">
          <Router>
            <TabNav>
            {/* <NavLink 
              to='/actra-toronto-council' 
              className="tab-nav__link" 
              activeClassName="tab-nav__link--active">
                <h2 className="tab-nav__header">ACTRA Toronto Council</h2>
              </NavLink> */}
              <NavLink 
              to='/about' 
              exact 
              className="tab-nav__link" 
              activeClassName="tab-nav__link--active">
                <h2 className="tab-nav__header">About</h2>
              </NavLink>
              <NavLink 
              to='/resume' 
              className="tab-nav__link" 
              activeClassName="tab-nav__link--active">
                <h2 className="tab-nav__header">Resume</h2>
                </NavLink>
              <NavLink 
              to='/demo-reel' 
              className="tab-nav__link" 
              activeClassName="tab-nav__link--active">
                <h2 className="tab-nav__header">Demo Reel</h2>
              </NavLink>
              <NavLink 
              to='/photos' 
              className="tab-nav__link" 
              activeClassName="tab-nav__link--active">
                <h2 className="tab-nav__header">Photos</h2>
              </NavLink>
              
            </TabNav>
            <Switch>
              <Route path='/about' render={() => {
                return <About />}} />
              <Route path='/resume' render={() => {
                return <CreditList />}} />
              <Route path='/demo-reel' render={() => {
                return (
                <DemoReel />
                )}} />
              <Route path='/photos' render={() => {
                return <Gallery />}} />
                {/* <Route path='/actra-toronto-council' render={() => {
                return <Campaign />}} /> */}
              <Route path='/hidden' render={() => {
              return <Hidden />}} />
              <Redirect from='/' to='/about' exact/>
              <Redirect from='/actra-toronto-council' to='/about' exact/>
            </Switch>
          </Router>
        </section>
      </main>
      

      <Footer />
    </>
    
  );
}

export default App;
