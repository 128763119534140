import React, {useState} from 'react';
import { galleryMisc, galleryEe, galleryMoby } from '../../util';


const Gallery = () => {

    const [imageView, setimageView] = useState('');
    const [imageOpen, setImageOpen] = useState('');

    const handleImageClick = (e, imageUrl) => {
        
        e.preventDefault();

        setimageView(imageUrl);

        if (e && imageOpen === '') {
            setImageOpen('active');
        }

        return null;
    }

    const handleImageClose = (e) => {
        if (e && imageOpen === 'active') {
            return setImageOpen('');
        } else {
            return null;
        }
        
    }

    return (
        
        <div className="gallery">
            <h6 className="heading1">Promotional</h6>
            <div className="gallery__container">
                
                {galleryMisc.map( (image) => {
                    return (
                        <div onClick={(e) => {handleImageClick(e, image.urlFull)}} key={image.id} id={image.id} className="gallery__thumb">
                            <img src={image.url} alt={image.alt} className="image" />
                        </div>
                    )
                })}
            </div>
            <h6 className="heading1">Elephant Empire Sketch Comedy</h6>
            <div className="gallery__container">
                
                {galleryEe.map( (image) => {
                    return (
                        <div onClick={(e) => {handleImageClick(e, image.urlFull)}} key={image.id} id={image.id} className="gallery__thumb">
                            <img src={image.url} alt={image.alt} className="image" />
                        </div>
                    )
                })}
            </div>
            <h6 className="heading1">On Set: Moby Dick</h6>
            <div className="gallery__container">
                
                {galleryMoby.map( (image) => {
                    return (
                        <div onClick={(e) => {handleImageClick(e, image.urlFull)}} key={image.id} id={image.id} className="gallery__thumb">
                            <img src={image.url} alt={image.alt} className="image" />
                        </div>
                    )
                })}
            </div>
            <div onClick={(e) => {handleImageClose(e)}} className={`overlay overlay--${imageOpen}`}>
            <img src={imageView} alt='' className="image overlay__image" />
            </div>
        </div>
        
    );
};

export default Gallery;