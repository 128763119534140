import React from 'react';
// import IntroAnimation from '../IntroAnimation/IntroAnimation';

const Header = () => {

    return (
        <header className="header">      
            {/* <IntroAnimation /> */}
            <h1 className="heading2">
                Matt Lemche
            </h1>
            <h4 className="header__subtitle">Performer&ensp;|&ensp;Creator</h4>
            
        
        </header>
    );
};

export default Header;