import React from 'react';
import {filmTvCredits, theatreCredits} from '../../util';

const CreditList = () => {

    


    return (
        <>
            <article className="article">
                <div className="credits">
                    <div className="credits__column">
                        <div className="credits__header">
                            <h5 className="heading1">
                                Film and TV
                            </h5>
                            
                        </div>
                        <div className="credits__list">
                            {filmTvCredits.map( (credit) => {
                                
                                return (
                                    
                                    <div key={credit.id} className="credit">
                                        <div className="credit__head">
                                            <div className="credit__title">
                                                {credit.title} <span className="text-block">{credit.titleTwo ? credit.titleTwo : ''}</span> <span className="credit__format">({credit.format})</span>
                                            </div>
                                            {/* <div className="credit__year">
                                                {credit.year}
                                            </div> */}
                                        </div>
                                        <div className="credit__sub">
                                            <div className="credit__role">
                                                {credit.role}
                                            </div>
                                            <div className="credit__dir">
                                               {credit.director ? 'Dir. ' + credit.director : ''}
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    )
                    
                            })}
                        </div>
                        <a href="https://www.imdb.com/name/nm0501211/" target="_blank" rel="noreferrer">View Film and TV credits on IMDb.com</a>
                    </div>
                    <div className="credits__column">
                        <div className="credits__header">
                            <h5 className="heading1">
                                Stage
                            </h5>
                        </div>
                        <div className="credits__list">
                            {theatreCredits.map( (credit) => {
                                
                                return (
                                    
                                    <div key={credit.id} className="credit">
                                        <div className="credit__head">
                                            <div className="credit__title">
                                                {credit.title} <span className="credit__format">({credit.format})</span>
                                            </div>
                                            {/* <div className="credit__year">
                                                {credit.year}
                                            </div> */}
                                        </div>
                                        <div className="credit__sub">
                                            <div className="credit__role">
                                                {credit.role}
                                            </div>
                                            <div className="credit__dir">
                                                Dir. {credit.director}
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    )
                    
                            })}
                        </div>
                    </div>
                </div>
                

                
                
            </article>
        </>
    );
};

export default CreditList;